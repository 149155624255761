import React, { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import request from "graphql-request";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ReactMarkdown from "react-markdown";
import "../assets/css/markdown.scss";
function PrivacyPage() {
  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState(true);
  const locale = useIntl();
  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          document(locale: "${locale.locale}") {
            privacy
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setDocument(items.document.privacy);
      setLoading(false);
    });
  }, []);

  return (
    <Layout>
      {loading ? <Loading /> : <ReactMarkdown>{document}</ReactMarkdown>}
    </Layout>
  );
}

export default PrivacyPage;
